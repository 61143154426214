import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import './index.sass';

const messages = defineMessages({
	transferring: {
		id: 'messageState.transferring',
		defaultMessage: 'Atendimento transferido'
	},
	talking: {
		id: 'messageState.talking',
		defaultMessage: 'Atendimento iniciado'
	},
	unavailable: {
		id: 'messageState.unavailable',
		defaultMessage: 'Atendimento não está disponível'
	},
	finished: {
		id: 'messageState.finished',
		defaultMessage: 'Atendimento finalizado'
	},
	expired: {
		id: 'messageState.expired',
		defaultMessage: 'Atendimento encerrado automaticamente por inatividade do cliente'
	},
	missed: {
		id: 'messageState.missed',
		defaultMessage: 'Atendimento perdido'
	},
	missedWhatsApp: {
		id: 'messageState.missedWhatsApp',
		defaultMessage: 'Atendimento perdido devido ao término da janela de 24h de atendimento (após última mensagem do usuário), conforme determinação do WhatsApp.'
	},
	client: {
		id: 'messageState.client',
		defaultMessage: 'pelo cliente'
	},
	pending: {
		id: 'messageState.pending',
		defaultMessage: 'deixou o atendimento pendente'
	},
	by: {
		id: 'messageState.by',
		defaultMessage: 'por'
	},
	redistributed: {
		id: 'messageState.redistributed',
		defaultMessage: 'Atendimento redistribuído automaticamente por inatividade do agente'
	}
});

const MessageState = ({ settings, messageInfo, intl }) => {
	const { formatMessage } = intl;
	const {
		interactionType,
		agentName,
		status,
		createdAt,
		expired
	} = messageInfo;
	const messageStates = {
		transferring: `${formatMessage(messages.transferring)} ${agentName ? `${formatMessage(messages.by)} ${agentName}` : ''} - ${createdAt}`,
		talking: `${formatMessage(messages.talking)} ${agentName ? `${formatMessage(messages.by)} ${agentName}` : ''} - ${createdAt}`,
		unavailable: `${formatMessage(messages.unavailable)} ${agentName ? ` - ${agentName}` : ''} - ${createdAt}`,
		ended: expired
			? `${formatMessage(messages.expired)} - ${createdAt}`
			: `${formatMessage(messages.finished)} ${agentName ? `${formatMessage(messages.by)} ${agentName}` : formatMessage(messages.client)} - ${createdAt}`,
		missed: interactionType === 'WHATSAPP'
			? `${formatMessage(messages.missedWhatsApp)} - ${createdAt}`
			: `${formatMessage(messages.missed)} - ${createdAt}`,
		postponed: `${agentName} ${formatMessage(messages.pending)} - ${createdAt}`,
		pending: `${agentName} ${formatMessage(messages.pending)} - ${createdAt}`,
		redistributed: `${formatMessage(messages.redistributed)} - ${createdAt}`
	};

	const { fontSize = '14px' } = settings;

	return (
		<div className="MessageState">
			<span style={{ fontSize }}>{messageStates[status.toLowerCase()]}</span>
		</div>
	);
};

MessageState.propTypes = {
	settings: PropTypes.shape({
		fontSize: PropTypes.string
	}).isRequired,
	messageInfo: PropTypes.shape({
		interactionType: PropTypes.string,
		agentName: PropTypes.string,
		status: PropTypes.string.isRequired,
		createdAt: PropTypes.string.isRequired,
		expired: PropTypes.bool
	}).isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
};

const mapStateToProps = state => ({
	settings: state.agent.info.settings
});

export default connect(mapStateToProps)(injectIntl(MessageState));
