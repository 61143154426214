import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';

import './index.sass';

import Spinner from '../../Atoms/Spinner';
import Button from '../Buttons/Button';
import Modal from '../Modal';
import Text from '../../Atoms/Text';
import ChannelImage from '../../Atoms/ChannelImage';

const messages = defineMessages({
	close: {
		id: 'activeInteractionsModal.close',
		defaultMessage: 'Fechar'
	},
	initializing: {
		id: 'activeInteractionsModal.initializing',
		defaultMessage: 'Iniciando atendimento...'
	},
	unableToInit: {
		id: 'activeInteractionsModal.unableToInit',
		defaultMessage: 'Não foi possível iniciar a interação'
	}
});

const ActiveInteractionsModal = ({ activeInfo, updateCreatingInteraction, intl }) => {
	const { formatMessage } = intl;
	const { type, info } = activeInfo;

	const [finishedTimeOut, setFinishedTimeOut] = useState(false);
	const updateFinishedTimeOut = useCallback(() => setFinishedTimeOut(true), []);
	useEffect(() => {
		let timerHandle = setTimeout(() => {
			updateFinishedTimeOut();
			timerHandle = 0;
		}, 8000);

		return () => {
			if (timerHandle) {
				clearTimeout(timerHandle);
				timerHandle = 0;
			}
		};
	});

	return (
		<Modal
			className="ActiveInteractionsModal"
			onClose={finishedTimeOut ? () => updateCreatingInteraction({}) : () => {}}
		>
			<div className="ActiveInteractionsModal__info">
				<span>
					<ChannelImage name={type} />
				</span>
				<Text>{info}</Text>
			</div>
			<div className="ActiveInteractionsModal__loader">
				<Text>
					{finishedTimeOut ? (
						formatMessage(messages.unableToInit)
					) : (
						formatMessage(messages.initializing)
					)}
				</Text>
				{finishedTimeOut ? (
					<Button
						click={() => updateCreatingInteraction({})}
						selected
					>
						{formatMessage(messages.close)}
					</Button>
				) : <Spinner />}
			</div>
		</Modal>
	);
};

ActiveInteractionsModal.propTypes = {
	activeInfo: PropTypes.shape({
		type: PropTypes.string,
		info: PropTypes.string
	}).isRequired,
	updateCreatingInteraction: PropTypes.func.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func
	}).isRequired
};

export default injectIntl(ActiveInteractionsModal);
