/* eslint-disable consistent-return */
/* eslint-disable no-unused-expressions */
import './index.sass';

import React, {
	Fragment,
	useEffect,
	useCallback,
	useRef,
	useState,
	useMemo
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';
import { MESSAGE_CONTENT_TYPE, MESSAGE_ORIGIN, MESSAGE_STATUS } from '../../../shared/consts';
import {
	timeInfoFormat2,
	timeInfoFormat4,
	mapNewMessageItem
} from '../../../shared/utility';
import * as actions from '../../../store/actions';
import MessageWrapper from '../../Molecules/Messages/MessageWrapper';
import MessageDate from '../../Molecules/Messages/MessageDate';
import MessageTyping from '../../Molecules/Messages/MessageTyping';
import ImgSvg from '../../Atoms/ImgSvg';
import Text from '../../Atoms/Text';

const translations = defineMessages({
	rejected: {
		id: 'messageState.rejected',
		defaultMessage: 'A mensagem acima não pôde ser enviada, pois o cliente está sendo atendido em outra plataforma da Zenvia.'
	},
	you: {
		id: 'messageList.you',
		defaultMessage: 'Você'
	},
	newMessages: {
		id: 'messageList.newMessages',
		defaultMessage: 'Novas mensagens'
	},
	newMessage: {
		id: 'messageList.newMessage',
		defaultMessage: 'Nova mensagem'
	}
});

const MessageList = ({
	typing,
	messages = [],
	intl,
	currentInteraction,
	settings,
	isInternalHistory,
	isChatHistory,
	currentAgentName = '',
	clearPendingMessages
}) => {
	const { formatMessage } = intl;
	const {
		currentState,
		interactionHash,
		interactionType,
		rejected = false,
		unreadMessages,
		media
	} = currentInteraction;
	const formattedMessages = messages.map(message => mapNewMessageItem(message));

	const messagesContainerRef = useRef(null);
	const prevInteractionRef = useRef(currentInteraction);

	const [isFirstLoad, setIsFirstLoad] = useState(true);
	const [isEndContainer, setIsEndContainer] = useState(false);

	const scrollToBottom = useCallback(() => {
		if (messagesContainerRef.current && !isChatHistory) {
			messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
		}
	}, [isChatHistory]);

	useEffect(() => {
		if ((isFirstLoad || currentInteraction.interactionHash !== prevInteractionRef.current.interactionHash) && messages.length > 1) {
			setTimeout(() => {
				scrollToBottom();
				prevInteractionRef.current = currentInteraction;
			}, 10);
			setIsFirstLoad(false);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentInteraction, scrollToBottom]);

	useEffect(() => {
		const lastMessage = messages[messages.length - 1] || {};
		const lastMessageIsFromAgent = lastMessage.origin === 'agent';

		if (isEndContainer || lastMessageIsFromAgent) {
			scrollToBottom();
		}
	}, [isEndContainer, messages, scrollToBottom]);

	// Search for referenced messages and inserts them into the original message.
	formattedMessages.forEach((message, key) => {
		if (message.referenceId) {
			const referencedMessage = formattedMessages.find(
				x => x.messageId === message.referenceId
			);
			formattedMessages[key].referencedMessage = {
				agentName: referencedMessage.agentName,
				referenceId: referencedMessage.messageId,
				content: referencedMessage.content,
				contentType: referencedMessage.contentType,
				url: referencedMessage.url
			};
		}
	});

	const { fontSize = '14px' } = settings;

	useEffect(() => {
		if (!messagesContainerRef.current) return;

		const handleScroll = () => {
			const container = messagesContainerRef.current;

			if (!container) return;

			const { scrollTop, scrollHeight, clientHeight } = container;

			if (scrollHeight <= clientHeight) {
				setIsEndContainer(true);
				return;
			}

			const isAtBottom = Math.abs(scrollTop + clientHeight - scrollHeight) <= 1;
			setIsEndContainer(isAtBottom);

			if (isAtBottom) {
				clearPendingMessages(interactionHash);
			}
		};

		const containerRef = messagesContainerRef.current;

		handleScroll();

		containerRef.addEventListener('scroll', handleScroll);

		return () => {
			containerRef.removeEventListener('scroll', handleScroll);
		};
	}, [clearPendingMessages, interactionHash]);

	const renderMessages = useCallback(() => {
		let lastDate = null;

		return formattedMessages.map((message) => {
			const {
				createdAt, messageId, origin, contentType, status
			} = message;

			if (contentType === MESSAGE_CONTENT_TYPE.STATE
				&& [MESSAGE_STATUS.NOT_ANSWER, MESSAGE_STATUS.QUEUED, MESSAGE_STATUS.RINGING].includes(status)) {
				return null;
			}

			let messageDate = null;

			if (createdAt && timeInfoFormat4(createdAt) !== lastDate) {
				lastDate = timeInfoFormat4(createdAt);
				messageDate = <MessageDate fontSize={fontSize} key={lastDate} date={lastDate} />;
			}

			const messageComponent = (
				<MessageWrapper
					key={messageId || new Date().getTime()}
					messageInfo={{
						...message,
						isInternalHistory,
						isChatHistory,
						agentName: currentAgentName === message.agentName ? formatMessage(translations.you) : message.agentName,
						name: message.agentName,
						isFromAgent: origin && origin.toLowerCase() === MESSAGE_ORIGIN.AGENT,
						isFromClient: origin && origin.toLowerCase() === MESSAGE_ORIGIN.CLIENT,
						isFromInternalAgent: origin && origin.toLowerCase() === MESSAGE_ORIGIN.INT_AGENT,
						isFromInternalAdmin: origin && origin.toLowerCase() === MESSAGE_ORIGIN.INT_ADMIN,
						createdAt: timeInfoFormat2(createdAt),
						interactionType,
						rejected,
						media
					}}
				/>
			);

			return (
				<Fragment key={messageId || new Date().getTime()}>
					{messageDate}
					{messageComponent}
				</Fragment>
			);
		});
	}, [currentAgentName, fontSize, formatMessage, formattedMessages, interactionType, isChatHistory, isInternalHistory, rejected, media]);

	const renderedMessages = useMemo(() => renderMessages(), [renderMessages]);

	return (
		<>
			<div
				className="message-list"
				ref={messagesContainerRef}
				style={{
					overflowY: 'auto',
					maxHeight: '100%',
					display: 'flex',
					flexDirection: 'column'
				}}
			>
				<div style={{ flex: '1 1 auto' }} />
				{renderedMessages}
				{rejected && (
					<div className="templateRejected">
						<ImgSvg name="exclamation-triangle-filled" />
						<Text>{formatMessage(translations.rejected)}</Text>
					</div>
				)}
				{!['ENDED', 'UNAVAILABLE'].includes(currentState) && typing && <MessageTyping />}
			</div>

			{unreadMessages && !isEndContainer && (
				<div
					onClick={scrollToBottom}
					className="new-messages"
					role="button"
					tabIndex="0"
				>
					<span>
						{`${unreadMessages} ${formatMessage(unreadMessages > 1 ? translations.newMessages : translations.newMessage)}`}
					</span>
					<i className="fa-regular fa-chevrons-down ml-2" />
				</div>
			)}
		</>
	);
};

MessageList.propTypes = {
	messages: PropTypes.arrayOf(
		PropTypes.shape({
			user: PropTypes.string,
			type: PropTypes.string
		})
	),
	settings: PropTypes.shape({
		fontSize: PropTypes.string
	}).isRequired,
	currentAgentName: PropTypes.string,
	typing: PropTypes.bool,
	isInternalHistory: PropTypes.bool,
	isChatHistory: PropTypes.bool,
	currentInteraction: PropTypes.shape({
		currentState: PropTypes.string,
		interactionHash: PropTypes.string,
		interactionType: PropTypes.string,
		origin: PropTypes.string,
		rejected: PropTypes.bool,
		unreadMessages: PropTypes.number,
		media: PropTypes.string
	}).isRequired,
	clearPendingMessages: PropTypes.func.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
};

const mapStateToProps = state => ({
	currentInteraction: state.interaction.currentInteraction,
	settings: state.agent.info.settings,
	currentAgentName: state.agent.info.name
});

const mapActionsToProps = dispatch => ({
	clearPendingMessages: interactionHash => dispatch(actions.clearPendingMessages(interactionHash))
});

export default connect(mapStateToProps, mapActionsToProps)(injectIntl(MessageList));
