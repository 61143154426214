import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';

import './index.sass';
import * as actions from '../../../store/actions';
import ImgSvg from '../../Atoms/ImgSvg';
import ChannelImage from '../../Atoms/ChannelImage';
import Text from '../../Atoms/Text';
import ClientForm from '../ClientForm';
import ClientSearch from '../ClientSearch';
import {
	interactionTypeLabel
} from '../../../shared/utility';
import ActiveInteractions from '../ActiveInteractions';
import Wrapper from '../../Atoms/Wrapper';

const messages = defineMessages({
	notRegistered: {
		id: 'clientInfo.notRegistered',
		defaultMessage: 'Não identificado'
	},
	editClient: {
		id: 'clientInfo.editClient',
		defaultMessage: 'Editar cliente'
	},
	searchClient: {
		id: 'clientInfo.searchClient',
		defaultMessage: 'Pesquisar cliente'
	},
	protocol: {
		id: 'clientInfo.protocol',
		defaultMessage: 'Protocolo'
	},
	department: {
		id: 'clientInfo.department',
		defaultMessage: 'Departamento'
	},
	resumeContact: {
		id: 'clientInfo.resumeContact',
		defaultMessage: 'Retomar contato'
	},
	conversationDetails: {
		id: 'clientInfo.conversationDetails',
		defaultMessage: 'Detalhes do atendimento'
	},
	clientData: {
		id: 'clientInfo.clientData',
		defaultMessage: 'Dados do cliente'
	},
	channel: {
		id: 'clientInfo.channel',
		defaultMessage: 'Canal'
	}
});

const ClientInfo = ({
	currentInteractionHash,
	onSetEditCustomer,
	customerInfo = {},
	editingCustomer,
	blockedToInteract,
	intl,
	blockEditCustomerInfo,
	isHistoryInteraction,
	currentInteraction
}) => {
	const { formatMessage } = intl;
	const {
		id,
		department,
		interactionType
	} = currentInteraction;
	const showEdit = editingCustomer ? editingCustomer.value : false;
	const departmentName = department && department.constructor === Object ? department.name : '';

	const [showSearch, setShowSearch] = useState(false);

	const activeEdit = useCallback(() => {
		onSetEditCustomer({ hash: currentInteractionHash, value: true });
	}, [currentInteractionHash, onSetEditCustomer]);

	const inactiveEdit = useCallback(() => {
		setShowSearch(false);
		onSetEditCustomer({ hash: currentInteractionHash, value: false });
	}, [currentInteractionHash, onSetEditCustomer]);

	const renderIcons = () => {
		if (!showEdit && !showSearch) {
			return (
				<>
					<Wrapper
						ariaLabel={formatMessage(messages.searchClient)}
						action={() => setShowSearch(true)}
					>
						<ImgSvg name="search" />
					</Wrapper>
					<Wrapper
						ariaLabel={formatMessage(messages.editClient)}
						action={() => activeEdit()}
					>
						<ImgSvg name="edit-profile-off" />
					</Wrapper>
				</>
			);
		}

		return (
			<Wrapper
				ariaLabel={formatMessage(messages.editClient)}
				action={() => (showSearch ? setShowSearch(false) : inactiveEdit())}
			>
				<ImgSvg name="close" />
			</Wrapper>
		);
	};

	return (
		<div className="ClientInfo">
			<div className="ClientInfo__main">
				<div className="ClientInfo__activeInteractions">
					<Text size="biggest">
						{formatMessage(messages.resumeContact)}
					</Text>
					<div className="ClientInfo__activeInteractions__actions">
						<ActiveInteractions
							fields={customerInfo.fields}
							customerKey={customerInfo.customerKey}
							currentInteraction={currentInteraction}
							emailType="customerRecontact"
						/>
					</div>
				</div>
				<div className="ClientInfo__main__interaction">
					<Text size="biggest">{formatMessage(messages.conversationDetails)}</Text>
					{id && (
						<div className="ClientInfo__main__interaction__row">
							<Text size="bigger">
								{formatMessage(messages.protocol)}
							</Text>
							<Text size="bigger">
								#
								{id}
							</Text>
						</div>
					)}
					<div className="ClientInfo__main__interaction__row">
						<Text size="bigger">
							{formatMessage(messages.channel)}
						</Text>
						<div className="ClientInfo__main__interaction__row__channel">
							<ChannelImage
								name={interactionType}
								suffix="gray"
							/>
							<Text size="bigger">{interactionTypeLabel(interactionType)}</Text>
						</div>
					</div>
					<div className="ClientInfo__main__interaction__row">
						<Text size="bigger">
							{formatMessage(messages.department)}
						</Text>
						<Text size="bigger">{departmentName}</Text>
					</div>
				</div>

				<div className="ClientInfo__main__container">
					<div className="ClientInfo__main__container__title">
						<Text size="biggest">{formatMessage(messages.clientData)}</Text>
						{(!blockedToInteract && !isHistoryInteraction) && (
							<div className="ClientInfo__main__container__title__actions">
								{renderIcons()}
							</div>
						)}
					</div>
					{showSearch
						? <ClientSearch activeEdit={activeEdit} />
						: (
							<ClientForm
								inactiveEdit={inactiveEdit}
								isEditing={showEdit}
								customerInfo={customerInfo}
								isHistoryInteraction={blockEditCustomerInfo || isHistoryInteraction}
							/>
						)
					}
				</div>
			</div>
		</div>
	);
};

ClientInfo.propTypes = {
	customerInfo: PropTypes.shape({
		fields: PropTypes.arrayOf(PropTypes.shape({
			name: PropTypes.string,
			photo: PropTypes.string,
			createdAt: PropTypes.string
		})),
		customerKey: PropTypes.string
	}),
	currentInteractionHash: PropTypes.string.isRequired,
	editingCustomer: PropTypes.shape({
		value: PropTypes.bool
	}).isRequired,
	onSetEditCustomer: PropTypes.func.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired,
	blockEditCustomerInfo: PropTypes.bool.isRequired,
	isHistoryInteraction: PropTypes.bool.isRequired,
	blockedToInteract: PropTypes.bool.isRequired,
	currentInteraction: PropTypes.shape({
		id: PropTypes.number,
		department: PropTypes.shape({
			name: PropTypes.string
		}),
		interactionType: PropTypes.string
	}).isRequired
};

const mapActionsToProps = dispatch => ({
	onSetEditCustomer: info => dispatch(actions.setEditCustomer(info))
});

const mapStateToProps = (state) => {
	const {
		currentInteractionHash,
		interactions,
		historyInteractions,
		missedInteractions,
		pendingInteractions
	} = state.interaction;
	const allInteractions = interactions.concat(pendingInteractions).concat(historyInteractions).concat(missedInteractions);

	return ({
		currentInteraction: allInteractions.find(({ interactionHash }) => interactionHash === currentInteractionHash) || {},
		currentInteractionHash,
		editingCustomer: state.interface.editingCustomer.find(({ hash }) => hash === currentInteractionHash) || { value: false },
		isHistoryInteraction: state.interaction.historyInteractions.some(({ interactionHash }) => interactionHash === currentInteractionHash),
		blockEditCustomerInfo: state.agent.blockEditCustomerInfo && !['ADMIN', 'ACCESS_SUPPORT'].includes(state.agent.info.profile)
	});
};

export default connect(mapStateToProps, mapActionsToProps)(injectIntl(ClientInfo));
