import './index.sass';

import React, {
	Fragment,
	useState,
	useCallback,
	useEffect
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';
import { useAuth0 } from '@auth0/auth0-react';
import {
	verifyPermissions,
	useMountEffect,
	isZenviaDomain,
	isAuth0Enabled,
	isLocal
} from '../../../shared/utility';
import Logo from '../../Atoms/Logo';
import AvatarName from '../../Atoms/AvatarName';
import * as actions from '../../../store/actions';
import NavBarItem from '../../Molecules/NavBarItem';
import ImgSvg from '../../Atoms/ImgSvg';
import Backdrop from '../../Molecules/Backdrop';
import CallControl from '../CallControl';
import EditAgent from '../EditAgent';
import BreakStatus from '../BreakStatus';
import Wrapper from '../../Atoms/Wrapper';
import NavbarDropDown from '../../Molecules/NavbarDropdown';
import BreakStatusTimer from '../../Molecules/BreakStatusTimer';
import Modal from '../../Molecules/Modal';
import FontSize from '../FontSize';

const messages = defineMessages({
	activate: {
		id: 'navBar.activate',
		defaultMessage: 'Ativar'
	},
	notification: {
		id: 'navBar.notification',
		defaultMessage: 'Notificação'
	},
	logout: {
		id: 'navBar.logout',
		defaultMessage: 'Sair'
	},
	available: {
		id: 'navBar.available',
		defaultMessage: 'Disponível'
	},
	unavailable: {
		id: 'navBar.unavailable',
		defaultMessage: 'Indisponível'
	},
	connecting: {
		id: 'navBar.connecting',
		defaultMessage: 'Conectando'
	},
	notificationInfo: {
		id: 'navBar.notificationInfo',
		defaultMessage: 'Ativar notificações'
	},
	chatInfo: {
		id: 'navBar.chatInfo',
		defaultMessage: 'Disponível para atendimentos'
	},
	tasks: {
		id: 'navBar.tasks',
		defaultMessage: 'Tarefas'
	},
	default: {
		id: 'navBar.home',
		defaultMessage: 'Caixa de atendimento'
	},
	contacts: {
		id: 'navBar.contacts',
		defaultMessage: 'Contatos'
	},
	history: {
		id: 'navBar.history',
		defaultMessage: 'Histórico'
	},
	dashboard: {
		id: 'navBar.dashboard',
		defaultMessage: 'Monitorar atendimentos'
	},
	disabledMic: {
		id: 'navBar.disabledMic',
		defaultMessage: 'Microfone desabilitado'
	},
	allowMic: {
		id: 'navBar.allowMic',
		defaultMessage: 'Habilite o microfone nas configurações do seu navegador'
	},
	activeInteraction: {
		id: 'navBar.activeInteraction',
		defaultMessage: 'Você possui interações ativas'
	},
	activeInteractionContent: {
		id: 'navBar.activeInteractionContent',
		defaultMessage: 'Finalize as interações para prosseguir'
	},
	informActivePhone: {
		id: 'navBar.informActivePhone',
		defaultMessage: 'Telefonia'
	},
	informActivePhoneContent: {
		id: 'navBar.informActivePhoneContent',
		defaultMessage: 'Ative a telefonia para prosseguir'
	},
	editAgent: {
		id: 'navBar.editAgent',
		defaultMessage: 'Editar agente'
	},
	availableForPhone: {
		id: 'navBar.availableForPhone',
		defaultMessage: 'Disponível para ligações'
	},
	voiceCall: {
		id: 'navBar.voiceCall',
		defaultMessage: 'Chamada de voz'
	},
	help: {
		id: 'navBar.help',
		defaultMessage: 'Ajuda'
	},
	moreOptions: {
		id: 'navBar.moreOptions',
		defaultMessage: 'Mostrar mais opções'
	},
	breakStatusTitle: {
		id: 'breakStatus.title',
		defaultMessage: 'Atualizar status'
	},
	breakStatusPreBreakBy: {
		id: 'breakStatus.preBreakBy',
		defaultMessage: 'Em pre-pausa por'
	},
	breakStatusBreakBy: {
		id: 'breakStatus.breakBy',
		defaultMessage: 'Em pausa por'
	},
	chatPreferences: {
		id: 'navBar.chatPreferences',
		defaultMessage: 'Preferências do atendimento'
	},
	changeFontSize: {
		id: 'navBar.changeFontSize',
		defaultMessage: 'Alterar tamanho do texto'
	},
	breakTimeRemainingTime: {
		id: 'breakStatus.breakTime.remainingTime',
		defaultMessage: 'Tempo restante'
	}
});

const NavBar = ({
	agentStatus = 'NOT_REGISTERED',
	unRegister,
	register,
	registerPhone,
	unregisterPhone,
	phoneData = {},
	hasActiveChat,
	registeredPhone,
	agent = { info: {} },
	agentBreakStatus = {},
	defineMainboxSection,
	defineSelectedInteraction,
	defineTemplate,
	defineTaskSidebar,
	mainBoxSection,
	fetchAgentContacts,
	fetchAgentDashboard,
	fetchDepartments,
	fetchAgentHistory,
	fetchAgentTasks,
	fetchTaskCount,
	fetchInteractionsCount,
	isFullAutomatic,
	showDial,
	onShowDial,
	onHideDial,
	onHideConference,
	intl,
	addNotification,
	setNotify,
	notify,
	defaultIdentity,
	identity = { logo_url: '' },
	hasPhone,
	template,
	taskCount,
	updateConferenceUsers,
	clearConferenceUsers,
	onChangeInputPhone,
	inputPhone,
	showConference,
	onlyChatOrPhone,
	showTasks,
	hasPhoneInteraction,
	onShowEditAgent,
	showEditAgent,
	updateAgentBreakStatus,
	onShowBreakStatus,
	showBreakStatus,
	fetchQueueInteractions,
	fetchInboxInteractions,
	hasAccountName,
	agentBlocked
}) => {
	const [showResponsiveMenu, setShowResponsiveMenu] = useState(false);
	const [showNavbarDropDown, setShowNavbarDropDown] = useState(false);
	const [showNavbarSettingsDropDown, setShowNavbarSettingsDropDown] = useState(false);
	const [showFontSize, setShowFontSize] = useState(false);
	const [isTimerEnded, setIsTimerEnded] = useState(() => {
		const storedTimer = JSON.parse(window.localStorage.getItem('breakTimer'));
		return storedTimer && storedTimer.isTimerEnded ? storedTimer.isTimerEnded : false;
	});

	const { formatMessage } = intl;
	const { account } = agent;
	const accountId = account && account.id;
	const { logout } = (isAuth0Enabled() && isZenviaDomain()) ? useAuth0() : { logout: () => {} };

	useMountEffect(() => {
		const taskFeature = agent && agent.features && agent.features.find(({ name }) => name === 'show_tasks');
		if (taskFeature && taskFeature.status === 'true') fetchTaskCount();
		if (isFullAutomatic) {
			fetchInteractionsCount();
			fetchInboxInteractions();
		} else {
			fetchQueueInteractions();
		}
	});

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const verifyBlocked = () => {
		const blockAgent = agentBlocked.blocked;

		if (blockAgent) {
			return true;
		}

		return false;
	};

	const onRegister = useCallback(() => {
		if (verifyBlocked()) {
			return;
		}
		if (agentStatus === 'REGISTERED') {
			unRegister();
		} else if (agentStatus === 'NOT_REGISTERED') {
			register();
		}
		window.localStorage.removeItem('breakTimer');
	}, [agentStatus, register, unRegister, verifyBlocked]);

	const onRegisterPhone = useCallback(() => {
		if (phoneData.name === 'totalVoice') {
			if (registeredPhone) {
				unregisterPhone(phoneData, hasActiveChat);
			} else {
				navigator.mediaDevices.getUserMedia({ audio: true }).then((media) => {
					if (media.active) registerPhone(phoneData, hasActiveChat);
				}).catch(() => {
					addNotification({
						title: formatMessage(messages.disabledMic),
						content: formatMessage(messages.allowMic),
						type: 'error',
						automaticClose: false
					});
				});
			}
		}
	}, [
		hasActiveChat,
		phoneData,
		registerPhone,
		registeredPhone,
		unregisterPhone,
		addNotification,
		formatMessage
	]);

	const onLogout = useCallback(() => {
		window.FS.anonymize();
		window.omzVish.logout();
		window.sessionStorage.clear();
		window.location.reload();
		window.localStorage.removeItem('showOnlyActiveInteractions');
		logout({ returnTo: window.location.origin });
	}, [logout]);

	const onChangeLanguage = useCallback((value) => {
		if (localStorage.getItem('locale') !== value) {
			localStorage.setItem('locale', value);
			document.location.reload(true);
		}
	}, []);

	const onChangeSection = useCallback((section) => {
		if (showResponsiveMenu) setShowResponsiveMenu(false);
		if (section === 'tasks') {
			defineTemplate(section);
			defineMainboxSection('');
			defineTaskSidebar('default');
			fetchAgentTasks({ filter: 'OPENED,EXPIRED' });
			fetchTaskCount();
			return null;
		}
		if (section === 'dashboard') {
			defineTemplate(section);
			defineMainboxSection('');
			fetchAgentDashboard();
			fetchDepartments();
			return null;
		}
		defineTemplate('default');
		defineMainboxSection(section);
		if (section === 'default' && mainBoxSection !== 'default') defineSelectedInteraction('');
		if (section === 'contacts') {
			fetchAgentContacts({ accountId, page: 1 });
			defineSelectedInteraction('');
		}
		if (section === 'history') {
			fetchAgentHistory({});
			defineSelectedInteraction('');
		}

		return null;
	}, [
		accountId,
		defineMainboxSection,
		defineSelectedInteraction,
		defineTaskSidebar,
		defineTemplate,
		fetchAgentContacts,
		fetchAgentDashboard,
		fetchAgentHistory,
		fetchAgentTasks,
		fetchDepartments,
		fetchTaskCount,
		mainBoxSection,
		showResponsiveMenu
	]);

	const toggleDial = useCallback(() => {
		if (showDial) {
			onHideDial();
			onHideConference();
		} else {
			onShowDial();
		}
	}, [onHideConference, onHideDial, onShowDial, showDial]);

	const openHelpPage = () => {
		window.open('https://zenvia.movidesk.com/kb/article/325040/sumario-zenvia-chat', '_blank');
	};

	const onInformActiveChat = useCallback(() => {
		addNotification({
			title: formatMessage(messages.activeInteraction),
			content: formatMessage(messages.activeInteractionContent),
			type: 'warning'
		});
	}, [addNotification, formatMessage]);

	const onInformActivePhone = useCallback(() => {
		addNotification({
			title: formatMessage(messages.informActivePhone),
			content: formatMessage(messages.informActivePhoneContent),
			type: 'warning'
		});
	}, [addNotification, formatMessage]);

	const onClickNotify = useCallback(() => {
		if (notify) {
			localStorage.setItem('activeNotification', false);
		} else {
			localStorage.setItem('activeNotification', true);
		}
		setNotify();
	}, [notify, setNotify]);

	const toggleResponsiveMenu = useCallback(() => {
		setShowResponsiveMenu(!showResponsiveMenu);
	}, [showResponsiveMenu]);

	const onClearBreakStatus = () => {
		const params = {
			breakStatus: {},
			action: 'UNPAUSE'
		};

		updateAgentBreakStatus(params);
	};

	const dialActive = onlyChatOrPhone
		? registeredPhone && !hasActiveChat && !hasPhoneInteraction
		: registeredPhone;
	const dialAction = (onlyChatOrPhone && hasActiveChat) || hasPhoneInteraction
		? onInformActiveChat
		: onInformActivePhone;
	const permissions = agent ? agent.permissions : [];
	const showContactList = verifyPermissions(permissions, 'contactList');
	const { logo_url: logoUrl, name } = identity;

	const adminURL = `${process.env.OMZ_ADMIN}/?autologin=true`;
	const openAdminPage = (token) => {
		const newWindow = window.open(adminURL, '_blank');
		window.addEventListener('message', (e) => {
			if (e.data === 'ready' && e.origin === new URL(adminURL).origin) {
				newWindow.postMessage({ token }, '*');
			}
		}, false);
	};

	const showAdminButton = agent ? agent.profile !== 'AGENT' && (isZenviaDomain() || isLocal()) : false;
	const navbarItems = [
		{ active: mainBoxSection === 'default', sectionName: 'default', show: true },
		{ active: template === 'dashboard', sectionName: 'dashboard', show: true },
		{ active: template === 'tasks', sectionName: 'tasks', show: showTasks },
		{ active: mainBoxSection === 'contacts', sectionName: 'contacts', show: showContactList },
		{ active: mainBoxSection === 'history', sectionName: 'history', show: true }
	];


	const dropDownItems = [
		{
			items: [{
				label: formatMessage(messages.breakStatusTitle),
				click: verifyBlocked() ? null : onShowBreakStatus,
				active: false,
				hasToggle: false,
				show: true
			}]
		},
		{
			items: [
				{
					label: formatMessage(messages.chatInfo),
					click: onRegister,
					active: agentStatus === 'REGISTERED',
					hasToggle: true,
					show: true
				},
				{
					label: formatMessage(messages.availableForPhone),
					click: verifyBlocked() ? null : onRegisterPhone,
					active: registeredPhone,
					hasToggle: true,
					show: hasPhone
				},
				{
					label: formatMessage(messages.notificationInfo),
					click: onClickNotify,
					active: notify,
					hasToggle: true,
					show: true
				},
				{
					label: formatMessage(messages.editAgent),
					click: onShowEditAgent,
					active: false,
					hasToggle: false,
					show: true
				}
			]
		}
	];

	const userStatus = (() => {
		if (!agentBreakStatus.id) return agentStatus;

		return agentBreakStatus.preBreak ? 'PREPAUSED' : 'PAUSED';
	})();


	useEffect(() => {
		const checkTimer = () => {
			const timerData = JSON.parse(window.localStorage.getItem('breakTimer')) || {};
			const timerEndTime = timerData.endedAt || null;
			if (timerEndTime) {
				const timeDifference = Date.now() - timerEndTime;
				if ((timeDifference < 60000 && timeDifference > -60000) && !timerData.lessOneMinute) {
					const updatedData = { ...timerData, lessOneMinute: true };
					window.localStorage.setItem('breakTimer', JSON.stringify(updatedData));
				}
				if (timeDifference >= 0 && timeDifference < 1000 && !timerData.isTimerEnded) {
					const updatedData = { ...timerData, isTimerEnded: true };
					window.localStorage.setItem('breakTimer', JSON.stringify(updatedData));
					setIsTimerEnded(true);
				}
			} else {
				setIsTimerEnded(false);
			}
		};
		checkTimer();

		const interval = setInterval(checkTimer, 1000);

		return () => clearInterval(interval);
	}, []);

	const dropDownCustomHeader = (
		<>
			<div className="NavBar__user__info">
				<div>
					<AvatarName url={agent.photo} status={userStatus} name={agent.name} />
				</div>
				<div>
					<span data-testid={agent.name} className="has-text-white">{agent.name}</span>
				</div>
			</div>
			{agentBreakStatus.id ? (
				<div className="NavBar__breakStatus has-text-white">
					<div className="NavBar__breakStatus--time">
						<span>{agentBreakStatus.preBreak ? formatMessage(messages.breakStatusPreBreakBy) : formatMessage(messages.breakStatusBreakBy)}</span>
						<BreakStatusTimer className="color-red" />
					</div>
					<div className="NavBar__breakStatus--selected">
						<div className="NavBar__breakStatus--info">
							<span>{agentBreakStatus.emoji}</span>
							<span>{agentBreakStatus.status}</span>
						</div>
						<Wrapper
							className="NavBar__breakStatus--action"
							action={() => onClearBreakStatus()}
						>
							<ImgSvg name="close" />
						</Wrapper>
					</div>
					<div className="NavBar__breakStatus--time mb-2">
						<span>{formatMessage(messages.breakTimeRemainingTime)}</span>
						<BreakStatusTimer className="color-green" endedAt isTimerEnded={isTimerEnded} />
					</div>
				</div>
			) : null}
		</>
	);

	const dropDownCustomFooter = (
		<>
			<button onClick={onLogout} type="button" className="button is-danger-text is-inverted" aria-haspopup="true">
				<span className="is-size-2 mr-2">{formatMessage(messages.logout)}</span>
				<span className="icon is-small">
					<i className="fa-solid fa-arrow-right-from-bracket" />
				</span>
			</button>
		</>
	);

	useEffect(() => {
		if (name || hasAccountName) document.title = `${name || 'Zenvia'} ${hasAccountName ? ` - ${agent.account.name}` : ''}`;
	}, [name, agent.account.name, hasAccountName]);

	return (
		<header className={`NavBar ${showResponsiveMenu ? 'NavBar--responsiveMenu' : ''}`} data-testid="component-NavBar">
			<div className="NavBar__navigation__wrapper">
				<Wrapper className="NavBar__navigation__icon" action={toggleResponsiveMenu}>
					<ImgSvg name={showResponsiveMenu ? 'close' : 'sandwich'} />
				</Wrapper>
				<div className="NavBar__navigation">
					<Logo url={logoUrl} defaultIdentity={defaultIdentity} />
					{navbarItems.map(({ active, sectionName, show }) => (
						<Fragment key={sectionName}>
							{show && (
								<NavBarItem active={active} click={() => onChangeSection(sectionName)}>
									{formatMessage(messages[sectionName])}
									{sectionName === 'tasks' && taskCount > 0 && <span className="NavBarItem__counter">{taskCount}</span>}
								</NavBarItem>
							)}
						</Fragment>
					))}
					{showAdminButton && (
						<NavBarItem active={false} click={() => openAdminPage(sessionStorage.getItem('omz_token'))} dataTestId="component-NavBar-AdminLink">
							Admin
							<span className="NavBarItem__icon">
								<ImgSvg name="white-external-link" />
							</span>
						</NavBarItem>
					)}
				</div>
				<Backdrop closeAction={toggleResponsiveMenu} clear />
			</div>
			<Logo url={logoUrl} defaultIdentity={defaultIdentity} />
			<div className="NavBar__actions">
				{hasPhone && (
					<Wrapper
						ariaLabel={formatMessage(messages.voiceCall)}
						className={`NavBar__dialButton ${dialActive ? 'NavBar__dialButton--active' : ''} ${showDial ? 'NavBar__dialButton--show' : ''} `}
						action={dialActive ? toggleDial : dialAction}
					>
						<ImgSvg name="dial-pad" />
					</Wrapper>
				)}

				<Wrapper ariaLabel={formatMessage(messages.moreOptions)} action={() => setShowNavbarSettingsDropDown(true)} className="NavBar__settings">
					<div className={`NavBar__settings__button ${showNavbarSettingsDropDown ? 'NavBar__settings__button--active' : ''}`}><ImgSvg name="settings" /></div>
					{showNavbarSettingsDropDown && (
						<NavbarDropDown
							dropDownItems={[
								{
									sectionName: formatMessage(messages.chatPreferences),
									items: [
										{
											label: formatMessage(messages.changeFontSize),
											click: () => setShowFontSize(!showFontSize),
											active: false,
											hasToggle: false,
											show: true
										}
									]
								}
							]}
							onChangeLanguage={onChangeLanguage}
							onClose={() => setShowNavbarSettingsDropDown(false)}
						/>
					)}
				</Wrapper>

				{!isZenviaDomain() && (
					<Wrapper
						ariaLabel={formatMessage(messages.help)}
						className="NavBar__helpButton"
						action={openHelpPage}
					>
						<div className="NavBar__helpButton__button">
							<ImgSvg name="help" />
						</div>

					</Wrapper>
				)}
				<Wrapper ariaLabel={formatMessage(messages.moreOptions)} action={() => setShowNavbarDropDown(true)} className={`NavBar__user ${showNavbarDropDown ? 'NavBar__user--active' : ''}`}>
					<div className="is-size-2"><AvatarName url={agent.photo} status={userStatus} name={agent.name} /></div>
					{showNavbarDropDown && (
						<NavbarDropDown
							dropDownCustomHeader={dropDownCustomHeader}
							dropDownCustomFooter={dropDownCustomFooter}
							dropDownItems={dropDownItems}
							onClose={() => setShowNavbarDropDown(false)}
						/>
					)}
				</Wrapper>
				{showDial && (
					<Modal onClose={toggleDial}>
						<CallControl
							phoneData={phoneData}
							updateConferenceUsers={updateConferenceUsers}
							clearConferenceUsers={clearConferenceUsers}
							onChangeInputPhone={onChangeInputPhone}
							inputPhone={inputPhone}
							showConference={showConference}
						/>
					</Modal>
				)}
			</div>
			{showEditAgent && <EditAgent />}
			{showBreakStatus && <BreakStatus />}
			{showFontSize && (<FontSize onClose={() => setShowFontSize(false)} />)}
			<div className="NavBar__border" />
		</header>
	);
};

const mapStateToProps = state => ({
	agent: state.agent.info,
	isFullAutomatic: state.agent.isFullAutomatic,
	agentStatus: state.agent.registerState,
	notify: state.agent.notify,
	identity: state.interface.identity,
	defaultIdentity: state.interface.defaultIdentity,
	mainBoxSection: state.interface.mainBoxSection,
	registeredPhone: state.agent.registeredPhone,
	hasPhone: state.interface.hasPhone,
	template: state.interface.template,
	taskCount: state.agent.taskCount,
	phoneData: state.agent.phoneData,
	inputPhone: state.interface.inputPhone,
	showDial: state.interface.showDial,
	showConference: state.interface.showConference,
	hasActiveChat: state.interaction.hasActiveChat,
	onlyChatOrPhone: state.interaction.onlyChatOrPhone,
	showTasks: state.agent.showTasks,
	defineTaskSidebar: PropTypes.func.isRequired,
	hasPhoneInteraction: state.interaction.interactions.some(({ interactionType, currentState }) => (
		interactionType.toLowerCase().includes('phone')
		&& !['ended', 'missed', 'replying'].includes(currentState.toLowerCase())
	)),
	showEditAgent: state.interface.showEditAgent,
	showBreakStatus: state.interface.showBreakStatus,
	agentBreakStatus: state.agent.agentBreakStatus,
	hasAccountName: state.agent.hasAccountName,
	agentBlocked: state.agent.agentBlocked
});

const mapActionsToProps = dispatch => ({
	register: () => {
		dispatch(actions.register());
	},
	unRegister: () => {
		dispatch(actions.unRegister());
	},
	setNotify: () => dispatch(actions.setNotify()),
	defineMainboxSection: section => dispatch(actions.defineMainboxSection(section)),
	registerPhone: (phoneData, hasActiveChat) => (
		dispatch(actions.registerPhone(phoneData, hasActiveChat))
	),
	unregisterPhone: (phoneData, hasActiveChat) => (
		dispatch(actions.unregisterPhone(phoneData, hasActiveChat))
	),
	defineSelectedInteraction: interactionHash => (
		dispatch(actions.defineSelectedInteraction(interactionHash))
	),
	fetchInteractionsCount: () => dispatch(actions.fetchInteractionsCount()),
	defineTemplate: template => dispatch(actions.defineTemplate(template)),
	updateConferenceUsers: user => dispatch(actions.updateConferenceUsers(user)),
	clearConferenceUsers: () => dispatch(actions.clearConferenceUsers()),
	onChangeInputPhone: inputInfo => dispatch(actions.changeInputPhone(inputInfo)),
	onShowDial: () => dispatch(actions.showDial()),
	onHideDial: () => dispatch(actions.hideDial()),
	onHideConference: () => dispatch(actions.hideConference()),
	defineTaskSidebar: option => dispatch(actions.defineTaskSidebar(option)),
	fetchAgentContacts: requestInfo => dispatch(actions.fetchAgentContacts(requestInfo)),
	fetchAgentHistory: requestInfo => dispatch(actions.fetchAgentHistory(requestInfo)),
	fetchAgentDashboard: requestInfo => dispatch(actions.fetchAgentDashboard(requestInfo)),
	fetchDepartments: () => dispatch(actions.fetchDepartments()),
	fetchAgentTasks: requestInfo => dispatch(actions.fetchAgentTasks(requestInfo)),
	fetchTaskCount: () => dispatch(actions.fetchTaskCount()),
	addNotification: notification => dispatch(actions.addNotification(notification)),
	onShowEditAgent: () => dispatch(actions.showEditAgent()),
	onShowBreakStatus: () => dispatch(actions.showBreakStatus()),
	updateAgentBreakStatus: info => dispatch(actions.updateAgentBreakStatus(info)),
	fetchQueueInteractions: () => dispatch(actions.fetchQueueInteractions(1, false, true)),
	fetchInboxInteractions: () => dispatch(actions.fetchInboxInteractions())
});

NavBar.propTypes = {
	agent: PropTypes.shape({
		id: PropTypes.number,
		registerState: PropTypes.string,
		info: PropTypes.shape({
			name: PropTypes.string,
			photo: PropTypes.string,
			id: PropTypes.number
		}),
		features: PropTypes.arrayOf(PropTypes.shape({
			status: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
		})),
		account: PropTypes.shape({
			id: PropTypes.number,
			name: PropTypes.string
		}),
		photo: PropTypes.string,
		name: PropTypes.string,
		permissions: PropTypes.arrayOf(PropTypes.string),
		profile: PropTypes.string
	}),
	agentBreakStatus: PropTypes.shape({
		id: PropTypes.number,
		emoji: PropTypes.string,
		status: PropTypes.string,
		preBreak: PropTypes.bool,
		startedAt: PropTypes.number
	}),
	isFullAutomatic: PropTypes.bool.isRequired,
	hasAccountName: PropTypes.bool.isRequired,
	agentStatus: PropTypes.oneOf(['NOT_REGISTERED', 'REGISTERING', 'REGISTERED', 'UNREGISTERING']),
	register: PropTypes.func.isRequired,
	unRegister: PropTypes.func.isRequired,
	notify: PropTypes.bool,
	setNotify: PropTypes.func.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired,
	defaultIdentity: PropTypes.bool,
	identity: PropTypes.shape({
		logo_url: PropTypes.string,
		name: PropTypes.string
	}),
	defineMainboxSection: PropTypes.func.isRequired,
	mainBoxSection: PropTypes.string.isRequired,
	registerPhone: PropTypes.func.isRequired,
	defineSelectedInteraction: PropTypes.func.isRequired,
	registeredPhone: PropTypes.bool.isRequired,
	hasPhone: PropTypes.bool.isRequired,
	defineTemplate: PropTypes.func.isRequired,
	template: PropTypes.string.isRequired,
	taskCount: PropTypes.number.isRequired,
	phoneData: PropTypes.shape({
		name: PropTypes.string
	}),
	updateConferenceUsers: PropTypes.func.isRequired,
	clearConferenceUsers: PropTypes.func.isRequired,
	unregisterPhone: PropTypes.func.isRequired,
	onChangeInputPhone: PropTypes.func.isRequired,
	inputPhone: PropTypes.string.isRequired,
	showDial: PropTypes.bool.isRequired,
	onShowDial: PropTypes.func.isRequired,
	onHideDial: PropTypes.func.isRequired,
	showConference: PropTypes.bool.isRequired,
	onHideConference: PropTypes.func.isRequired,
	hasActiveChat: PropTypes.bool.isRequired,
	onlyChatOrPhone: PropTypes.bool.isRequired,
	showTasks: PropTypes.bool.isRequired,
	defineTaskSidebar: PropTypes.func.isRequired,
	hasPhoneInteraction: PropTypes.bool.isRequired,
	fetchAgentContacts: PropTypes.func.isRequired,
	fetchAgentHistory: PropTypes.func.isRequired,
	fetchAgentDashboard: PropTypes.func.isRequired,
	fetchDepartments: PropTypes.func.isRequired,
	fetchAgentTasks: PropTypes.func.isRequired,
	fetchTaskCount: PropTypes.func.isRequired,
	fetchInteractionsCount: PropTypes.func.isRequired,
	addNotification: PropTypes.func.isRequired,
	onShowEditAgent: PropTypes.func.isRequired,
	showEditAgent: PropTypes.bool.isRequired,
	updateAgentBreakStatus: PropTypes.func.isRequired,
	onShowBreakStatus: PropTypes.func.isRequired,
	showBreakStatus: PropTypes.bool.isRequired,
	fetchQueueInteractions: PropTypes.func.isRequired,
	fetchInboxInteractions: PropTypes.func.isRequired,
	agentBlocked: PropTypes.shape({
		blocked: PropTypes.bool,
		blockModalViewed: PropTypes.bool
	})
};

export default connect(mapStateToProps, mapActionsToProps)(injectIntl(NavBar));
